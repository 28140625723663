import React, { useEffect, useState, useRef } from 'react';
import { faArrowLeft, faBars, faEnvelope, faGlobe, faLink, faMapMarkerAlt, faPhoneAlt, faVoicemail, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useLocation } from 'react-router-dom';
import Footer from '../components/footer';
import templatebankrio from '../img/template-bankrio-logo.png';
import templatebesmart from '../img/template-besmart-logo.png';
import templateinvestsmart from '../img/template-investsmart-logo.png';
import templateinvestsmart2 from '../img/template-investsmart-logo-nova.png';
import templateis from '../img/logos/logo_is_assessoria.png';
import Alert from 'react-bootstrap/Alert';
import Alerta from '../components/Alerta';
import logobe from '../img/logos/logo_besmart.gif'
import logois from '../img/logos/logo_is.gif'

import templatebnkrio from '../img/template-bnkrio-logo.png';
import templateimoveis from '../img/BeSmart_Logos_imoveis.png';
import domtoimage from 'dom-to-image';
import { useAlert, positions } from "react-alert";

import domainIcon from '../img/icons/domain.png';
import globeIcon from '../img/icons/globe-solid-lg.png';
import facebookIcon from '../img/icons/facebook-f-brands-lg.png';
import instagramIcon from '../img/icons/instagram-brands-lg.png';
import linkedinIcon from '../img/icons/linkedin-in-brands-lg.png';
import twitterIcon from '../img/icons/twitter-brands.png';

import mapBlue from '../img/icons/temp-bnkrio/map-marker-alt-solid-lg.png';
import mapPurple from '../img/icons/temp-investsmart/map-marker-alt-solid-lg.png';
import mapYellow from '../img/icons/temp-bankrio/map-marker-alt-solid-lg.png';

import linkBlue from '../img/icons/temp-bnkrio/link-solid-lg.png';
import linkPurple from '../img/icons/temp-investsmart/link-solid-lg.png';
import linkYellow from '../img/icons/temp-bankrio/link-solid-lg.png';

import phoneBlue from '../img/icons/temp-bnkrio/phone-alt-solid-lg.png';
import phonePurple from '../img/icons/temp-investsmart/phone-alt-solid-lg.png';
import phoneYellow from '../img/icons/temp-bankrio/phone-alt-solid-lg.png';

import mailBlue from '../img/icons/temp-bnkrio/envelope-solid-lg.png';
import mailPurple from '../img/icons/temp-investsmart/envelope-solid-lg.png';
import mailYellow from '../img/icons/temp-bankrio/envelope-solid-lg.png';

import voicemailBlue from '../img/icons/temp-bnkrio/voicemail-solid-lg.png';
import voicemailPurple from '../img/icons/temp-investsmart/voicemail-solid-lg.png';
import voicemailYellow from '../img/icons/temp-bankrio/voicemail-solid-lg.png';

import G20 from '../img/Selo_n1_G20.png';

import img1 from '../img/1.png';
import img2 from '../img/2.png';
import img3 from '../img/3.png';
import img4 from '../img/4.png';
import img5 from '../img/5.png';



const StepThree = () => {
  const outlook = useRef(null);
  const alert = useAlert();
  const [modelo, setModelo] = useState(0);
  const [codeToCopy, setCodeToCopy] = useState(null);
  const { state } = useLocation();

  function finalizar_assinatura() {
    setModelo(state.template);
  }

  async function downloadImage() {
    var node = document.getElementById('signature');
    domtoimage.toPng(node)
      .then((result) => {
        var link = document.createElement('a');
        link.download = 'assinatura.png';
        link.href = result;
        link.click();
      })
  }

  async function copyElement() {
    // var node = document.getElementById('signature');
    // setCodeToCopy(node.innerHTML);

    // setTimeout(() => {
    //   var copyText = document.getElementById("textarea-copy");
    //   copyText.select();
    //   document.execCommand("copy");
    // }, 500);

    var doc = document
      , text = doc.getElementById('signature')
      , range, selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    }

    else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    alert.success('A assinatura foi copiada para a área de transferência')
  }

  function getPhoneImg() {
    switch (modelo) {
      case 0:
        return phoneYellow
        break;
      case 1:
        return phonePurple
        break;
      case 2:
        return phoneBlue
        break;

      default:
        return phoneYellow
        break;
    }
  }

  function getVoiceMailImg() {
    switch (modelo) {
      case 0:
        return voicemailYellow
        break;
      case 1:
        return voicemailPurple
        break;
      case 2:
        return voicemailBlue
        break;

      default:
        return voicemailYellow
        break;
    }
  }

  function getMailImg() {
    switch (modelo) {
      case 0:
        return mailYellow
        break;
      case 1:
        return mailPurple
        break;
      case 2:
        return mailBlue
        break;

      default:
        return mailYellow
        break;
    }
  }

  function getMapImg() {
    switch (modelo) {
      case 0:
        return mapYellow
        break;
      case 1:
        return mapPurple
        break;
      case 2:
        return mapBlue
        break;

      default:
        return mapYellow
        break;
    }
  }

  function getLinkImg() {
    switch (modelo) {
      case 0:
        return linkYellow
        break;
      case 1:
        return linkPurple
        break;
      case 2:
        return linkBlue
        break;

      default:
        return linkYellow
        break;
    }
  }

  function getColor() {
    // let color = getComputedStyle(document.getElementById('name')).getPropertyValue('--company-2nd-color')
    switch (modelo) {
      case 0:
        return "#9966ff"
        break;
      case 1:
        return "#9966ff"
        break;
      case 2:
        return "#9966ff"
        break;

      default:
        return "#9966ff"
        break;
    }
  }

  useEffect(() => {
    finalizar_assinatura();
  }, [])

  function closingFaqs() {
    document.getElementById('faqs-page').style.display = "none";
  };



  function goOutlook() {
    document.getElementById('faqs-page').style.display = "flex";
    outlook.current.scrollIntoView()
  };
  //Aqui aparece o layout para a magia acontecer FOrmatado em tabela pra ser mais amigavel com os correios eletronicos. 
  return (
    <>
      <div class="full-page-2">

        <div class="content-layout-2">
          <div class="header-layout">

          </div>
          <div class="body-layout-2" id={state.templateID}>
            <div class="content-body-2">
              <div class="window-content-3">
                <div class="signature-body-3">
                  <div class="header-body-2">
                    <div class="text-left pt-20"> <p><a href="/"><span class="voltar-editor"><i><FontAwesomeIcon icon={faArrowLeft} /></i>Voltar</span></a></p></div>
                    <h2 class="title-header-2">Sua Assinatura está pronta.</h2>
                    <h3 class="subtitle-header-3 pt-40">Agora é hora de colocar sua assinatura de e-mail para funcionar. Clique nos links abaixo para copiar sua assinatura e adicioná-la a um cliente de e-mail. </h3>

                    {
                      modelo === 0 ? (
                        <Alerta />
                      ) : null
                    }
                    {
                      modelo === 3 ? (
                        <Alerta />
                      ) : null
                    }


                  </div>
                  <div class="window-screen">
                    <div class="top-window">
                      <span class="btn-close"></span>
                      <span class="btn-min"></span>
                      <span class="btn-max"></span>
                    </div>
                    <div class="top-infomessage-window">
                      <span class="to-message">Para: <strong style={{ paddingLeft: 5 }}>Seu destinatário</strong></span>
                      <span class="subject-message">Assunto: <strong style={{ paddingLeft: 5 }}>Veja minha nova assinatura de e-mail</strong></span>
                    </div>

                    <div class="content-window">
                      <div id="signature" style={{ background: 'white' }}>
                        <table width={700} height={161}>
                          <tr height="80%">
                            <td width="20%">
                              <div style={{ height: 83, width: 83, borderRadius: '100%', overflow: 'hidden' }}>
                                <img height={85} width={85} src={state.profile} style={{ marginTop: -2, marginLeft: -1 }} />
                              </div>
                            </td>
                            <td width="30%">
                              <p style={{ fontFamily: 'Arial', fontSize: 18, marginBottom: 5 }}>{state.nome} {state.sobrenome}</p>
                              <p style={{ fontFamily: 'Arial', fontSize: 14 }}>{state.cargo}</p>
                            </td>
                            <td width="5%" style={{ borderLeft: `5px solid #ebff70` }} />
                            <td width="45%">
                              <table width="100%">
                                {
                                  state.cellphone || state.cellcomercial ? (
                                    <tr>

                                      <td>
                                        <a href={`tel:${state.cellphone}`} style={{ color: 'black', fontSize: 12, marginBottom: 0, fontFamily: 'Arial' }}>{state.cellphone}</a><a href={`tel:${state.cellcomercial}`} style={{ color: 'black', fontSize: 12, marginBottom: 0, fontFamily: 'Helvetica' }}>{state.cellcomercial.length > 0 ? ` | ${state.cellcomercial}` : ''}</a>
                                      </td>
                                    </tr>
                                  ) : null
                                }
                                <tr height="0px">
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>

                                  <td>
                                    <a href={`mailto:${state.email}`} style={{ color: 'black', marginBottom: 0, fontSize: 12, fontFamily: 'Arial' }}>{state.email}</a>
                                  </td>
                                </tr>
                                <tr height="0px">
                                  <td></td>
                                  <td></td>
                                </tr>
                                {
                                  state.url ? (
                                    <tr>

                                      <td>
                                        <a href={state.url} style={{ color: 'black', marginBottom: 0, fontSize: 12, fontFamily: 'Arial' }}>{state.url}</a>
                                      </td>
                                    </tr>
                                  ) : null
                                }
                                <tr height="0px">
                                  <td></td>
                                  <td></td>
                                </tr>
                                {
                                  state.endereco1 ? (
                                    <tr>

                                      <td>
                                        <p style={{ marginBottom: 0, fontSize: 12, fontFamily: 'Arial' }}>{state.endereco1}</p>
                                      </td>

                                    </tr>
                                  ) : null
                                }

                                {
                                  modelo === 0 ? (
                                    <>

                                      <table style={{ marginTop: '5px' }} >

                                        <tr>
                                          <td border="0">
                                            <a href="https://besmart.com.br/" border="0" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_globe_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.facebook.com/grupobesmart" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_facebook_solid_lg.png" border={0} />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.linkedin.com/company/besmartis/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_linkedin_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.instagram.com/be.smart.is/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_instagram_solid_lg.png" />
                                            </a>
                                          </td>
                                        </tr>
                                      </table>

                                    </>
                                  ) : null
                                }
                                {
                                  modelo === 2 ? (
                                    <>
                                      <table style={{ marginTop: '5px' }} >

                                        <tr>
                                          <td>
                                            <a href="https://investsmart.com.br/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_globe_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.facebook.com/InvestSmartXP" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_facebook_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.linkedin.com/company/investsmartxp/" style={{ display: 'block', textAlign: 'center', }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_linkedin_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.instagram.com/investsmart.xp/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={25} width={25} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_instagram_solid_lg.png" />
                                            </a>
                                          </td>
                                        </tr>
                                      </table>

                                    </>
                                  ) : null
                                }
                                {
                                  modelo === 3 ? (
                                    <>
                                      <table style={{ marginTop: '5px' }} >

                                        <tr>
                                          <td>
                                            <a href="https://www.besmartimoveis.com.br/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_globe_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.facebook.com/besmartimoveis" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_facebook_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.linkedin.com/company/besmartimoveis/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_linkedin_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href="https://www.instagram.com/be.smart_imoveis/" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_instagram_solid_lg.png" />
                                            </a>
                                          </td>
                                          <td>
                                            <a href=" https://www.youtube.com/channel/UCx3OKKUpY3jwu_KQT15akAg" style={{ display: 'block', textAlign: 'center' }}>
                                              <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/batch_youtube-brands-lg.png" />
                                            </a>
                                          </td>
                                        </tr>
                                      </table>

                                    </>
                                  ) : null
                                }
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table width={700} height={100}>
                          <tr></tr>
                          <tr height={90} style={{ /* borderTop: `1px solid ${getColor()}` */ }}>
                            {
                              modelo === 0 ? (
                                <>
                                  <td width="80%">
                                    <img height={60} width={700} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/logo_besmart.gif" />
                                  </td>

                                </>
                              ) : null
                            }

                            {
                              modelo === 1 ? (
                                <>
                                  <td width="50%">
                                    <img height={29} width={250} src={templateinvestsmart} />
                                    <img src={G20} width={114} />
                                  </td>
                                  <td width="20%">
                                    <table>
                                      <tr>
                                        <td>
                                          <a href="https://investsmart.com.br/" style={{ display: 'block', border: '0', textAlign: 'center' }}>
                                            <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_globe_solid_lg.png" />
                                          </a>
                                        </td>
                                        <td>
                                          <a href="https://www.facebook.com/InvestSmartXP" style={{ display: 'block', textAlign: 'center' }}>
                                            <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_facebook_solid_lg.png" />
                                          </a>
                                        </td>
                                        <td>
                                          <a href="https://www.linkedin.com/company/investsmartxp/" style={{ display: 'block', textAlign: 'center' }}>
                                            <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_linkedin_solid_lg.png" />
                                          </a>
                                        </td>
                                        <td>
                                          <a href="https://www.instagram.com/investsmart.xp/" style={{ display: 'block', textAlign: 'center' }}>
                                            <img style={{ display: 'block' }} height={30} width={30} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/icone_instagram_solid_lg.png" />
                                          </a>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </>
                              ) : null
                            }

                            {
                              modelo === 2 ? (
                                <>
                                  <td width="100%">
                                    <img height={60} width={700} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/logo_is.gif" />
                                  </td>

                                </>
                              ) : null
                            }
                            {
                              modelo === 4 ? (
                                <>
                                  <td width="100%">
                                    <img height={63} width={250} src={templateis} />
                                  </td>

                                </>
                              ) : null
                            }
                            {
                              modelo === 3 ? (
                                <>
                                  <td width="80%">
                                    <img height={60} width={700} src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/logo_imoveis.gif" />
                                  </td>
                                  <td width="20%">


                                  </td>
                                </>
                              ) : null
                            }

                          </tr>
                        </table>
                        {
                          modelo === 1 && (
                            <table width={700}>
                              <p style={{ width: '100%', fontSize: '10px', textAlign: 'justify' }}>
                                A InvestSmart Assessor de Investimento Ltda. é uma sociedade de assessores de investimento devidamente registrada na Comissão de Valores Mobiliários (CVM), conforme a Resolução CVM nº 178/2023. A InvestSmart Assessor de Investimento Ltda. atua no mercado financeiro e de capitais na qualidade de escritório credenciado da XP Investimentos CCTVM S/A, o que pode ser verificado por meio do site da própria XP Investimentos CCTVM S/A (<a href="www.xpi.com.br" style={{ color: '#9966ff' }}>www.xpi.com.br</a>)&gt; Sobre a XP&gt;  Encontre um escritório&gt;  Selecione o estado e a cidade que deseja pesquisar&gt;  Veja a lista dos assessores de investimento). Na forma da regulamentação da CVM, o assessor de investimento não

                                pode administrar ou gerir o patrimônio de investidores. O assessor de investimento é um preposto do intermediário XP Investimentos e depende da autorização prévia do cliente para realizar operações no mercado financeiro.

                                Esta mensagem, incluindo os seus anexos, contém informações confidenciais destinadas a indivíduo e propósito específicos, sendo protegida por lei. Caso você não seja a pessoa a quem foi dirigida a mensagem, deve apagá-la. É terminantemente proibida a utilização, acesso, cópia ou divulgação não autorizada das informações presentes nesta mensagem. As informações contidas nesta mensagem e em seus anexos são de responsabilidade de seu autor, não representando necessariamente ideias, opiniões, pensamentos ou qualquer forma de posicionamento por parte da InvestSmart Assessor de Investimento Ltda. Os investidores devem obter orientação financeira independente, com base em suas características pessoais, antes de tomar uma decisão de investimento.

                                A decisão de investimento e avaliação dos riscos são de exclusiva responsabilidade do cliente. Rentabilidade passada não é garantia de rentabilidade futura em nenhuma modalidade de investimento. Os riscos da operação com títulos de renda fixa estão na capacidade de o emissor (governo, em se tratando de títulos públicos, empresa ou instituições financeiras, em se tratando de títulos privados honrar a dívida (risco de crédito); na impossibilidade de venda do título ou na ausência de investidores interessados em adquiri-lo (risco de liquidez); e na possibilidade de variação da taxa de juros e dos indexadores (risco de mercado). Em se tratando de título privado, o risco de mercado inclui, ainda, o chamado prêmio do risco, que é aquele inerente à capacidade de pagamento da emissora do título. Apesar de ser classificado como produto de renda fixa, há risco de perda da totalidade ou parte do valor investido. CRI, CRA, FIDC e Debêntures não contam com a garantia do Fundo Garantidor de Crédito (FGC). CDB, LC, LCI e LCA contam com a garantia do FGC, conforme políticas, regras e limites próprios. Para maiores informações acesse <a href="www.fgc.org.br" style={{ color: '#9966ff' }}>www.fgc.org.br</a>.

                                Muito importante a adequada compreensão da natureza, forma de rentabilidade e riscos dos títulos de renda fixa antes da sua aquisição. Fundos de Investimento em geral não contam com garantia do seu administrador, do gestor, de qualquer mecanismo de seguro ou do FGC. A aplicação em Fundos de Investimento Imobiliários (FII) é recomendada para o perfil moderado ou agressivo. Há baixa liquidez das cotas de FII no cenário brasileiro. Ao considerar a aquisição de cotas de Fundos Investimento, potenciais investidores deverão realizar sua própria análise e avaliação sobre o Fundo em questão.

                                Leia o prospecto, o regulamento, o formulário de informações complementares e a lâmina de informações essenciais, quando disponíveis, antes de investir em qualquer fundo. Indica-se leitura cuidadosa da seção “Fatores de Risco”, a qual contém a descrição de certos riscos que podem afetar de maneira adversa o investimento no fundo em questão. O objetivo do fundo não caracteriza compromisso ou garantia por parte do administrador de que o mesmo será atingido. Ainda que o gestor da carteira do fundo mantenha sistema de gerenciamento de riscos, não há garantia de completa eliminação de perdas patrimoniais para o fundo e para o investidor. O investimento em ações e Certificados de Operações Estruturadas (COE) são de alto risco. Na realização de operações com derivativos existe a possibilidade de perdas superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais.

                                Para informações e dúvidas, favor contatar seu assessor de investimento. Para reclamações, favor contatar a Ouvidoria da XP Investimentos no telefone nº 0800-722-3730.</p>
                            </table>
                          )
                        }
                        {
                          modelo === 4 && (
                            <table width={700}>
                              <p style={{ width: '100%', fontSize: '10px', textAlign: 'justify' }}>

                                A IS Assessor de Investimento Ltda. é uma sociedade de assessores de investimento devidamente registrada na Comissão de Valores Mobiliários (CVM), conforme a Resolução CVM nº 178/2023. A IS Assessor de Investimento Ltda. atua no mercado financeiro e de capitais na qualidade de escritório credenciado da XP Investimentos CCTVM S/A, o que pode ser verificado por meio do site da própria XP Investimentos CCTVM S/A (<a href="www.xpi.com.br" style={{ color: '#9966ff' }}>www.xpi.com.br</a>)&gt;Sobre a XP&gt; Encontre um escritório&gt;Selecione o estado e a cidade que deseja pesquisar&gt;Veja a lista dos assessores de investimento). Na forma da regulamentação da CVM, o assessor de investimento não pode administrar ou gerir o patrimônio de investidores. O assessor de investimento é um preposto do intermediário XP Investimentos e depende da autorização prévia do cliente para realizar operações no mercado financeiro.

                                Esta mensagem, incluindo os seus anexos, contém informações confidenciais destinadas a indivíduo e propósito específicos, sendo protegida por lei. Caso você não seja a pessoa a quem foi dirigida a mensagem, deve apagá-la. É terminantemente proibida a utilização, acesso, cópia ou divulgação não autorizada das informações presentes nesta mensagem. As informações contidas nesta mensagem e em seus anexos são de responsabilidade de seu autor, não representando necessariamente ideias, opiniões, pensamentos ou qualquer forma de posicionamento por parte da IS Assessor de Investimento Ltda. Os investidores devem obter orientação financeira independente, com base em suas características pessoais, antes de tomar uma decisão de investimento.

                                A decisão de investimento e avaliação dos riscos são de exclusiva responsabilidade do cliente. Rentabilidade passada não é garantia de rentabilidade futura em nenhuma modalidade de investimento. Os riscos da operação com títulos de renda fixa estão na capacidade de o emissor (governo, em se tratando de títulos públicos, empresa ou instituições financeiras, em se tratando de títulos privados honrar a dívida (risco de crédito); na impossibilidade de venda do título ou na ausência de investidores interessados em adquiri-lo (risco de liquidez); e na possibilidade de variação da taxa de juros e dos indexadores (risco de mercado). Em se tratando de título privado, o risco de mercado inclui, ainda, o chamado prêmio do risco, que é aquele inerente à capacidade de pagamento da emissora do título. Apesar de ser classificado como produto de renda fixa, há risco de perda da totalidade ou parte do valor investido. CRI, CRA, FIDC e Debêntures não contam com a garantia do Fundo Garantidor de Crédito (FGC). CDB, LC, LCI e LCA contam com a garantia do FGC, conforme políticas, regras e limites próprios. Para maiores informações acesse <a href="www.fgc.org.br" style={{ color: '#9966ff' }}>www.fgc.org.br</a>.

                                Muito importante a adequada compreensão da natureza, forma de rentabilidade e riscos dos títulos de renda fixa antes da sua aquisição. Fundos de Investimento em geral não contam com garantia do seu administrador, do gestor, de qualquer mecanismo de seguro ou do FGC. A aplicação em Fundos de Investimento Imobiliários (FII) é recomendada para o perfil moderado ou agressivo. Há baixa liquidez das cotas de FII no cenário brasileiro. Ao considerar a aquisição de cotas de Fundos Investimento, potenciais investidores deverão realizar sua própria análise e avaliação sobre o Fundo em questão.

                                Leia o prospecto, o regulamento, o formulário de informações complementares e a lâmina de informações essenciais, quando disponíveis, antes de investir em qualquer fundo. Indica-se leitura cuidadosa da seção “Fatores de Risco”, a qual contém a descrição de certos riscos que podem afetar de maneira adversa o investimento no fundo em questão. O objetivo do fundo não caracteriza compromisso ou garantia por parte do administrador de que o mesmo será atingido. Ainda que o gestor da carteira do fundo mantenha sistema de gerenciamento de riscos, não há garantia de completa eliminação de perdas patrimoniais para o fundo e para o investidor. O investimento em ações e Certificados de Operações Estruturadas (COE) são de alto risco. Na realização de operações com derivativos existe a possibilidade de perdas superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais.

                                Para informações e dúvidas, favor contatar seu assessor de investimento. Para reclamações, favor contatar a Ouvidoria da XP Investimentos no telefone nº 0800-722-3730.
                              </p>
                            </table>
                          )
                        }
                        {
                          modelo === 2 && (
                            <table width={700}>
                              <p style={{ width: '100%', fontSize: '10px', textAlign: 'justify' }}>
                                A InvestSmart Assessor de Investimento Ltda. é uma sociedade de assessores de investimento devidamente registrada na Comissão de Valores Mobiliários (CVM), conforme a Resolução CVM nº 178/2023. A InvestSmart Assessor de Investimento Ltda. atua no mercado financeiro e de capitais na qualidade de escritório credenciado da XP Investimentos CCTVM S/A, o que pode ser verificado por meio do site da própria XP Investimentos CCTVM S/A (<a href="www.xpi.com.br" style={{ color: '#9966ff' }}>www.xpi.com.br</a>). Sobre a XP Encontre um escritório&gt; Selecione o estado e a cidade que deseja pesquisar&gt; Veja a lista dos assessores de investimento). Na forma da regulamentação da CVM, o assessor de investimento não pode administrar ou gerir o patrimônio de investidores. O assessor de investimento é um preposto do intermediário XP Investimentos e depende da autorização prévia do cliente para realizar operações no mercado financeiro.

                                Esta mensagem, incluindo os seus anexos, contém informações confidenciais destinadas a indivíduo e propósito específicos, sendo protegida por lei. Caso você não seja a pessoa a quem foi dirigida a mensagem, deve apagá-la. É terminantemente proibida a utilização, acesso, cópia ou divulgação não autorizada das informações presentes nesta mensagem. As informações contidas nesta mensagem e em seus anexos são de responsabilidade de seu autor, não representando necessariamente ideias, opiniões, pensamentos ou qualquer forma de posicionamento por parte da InvestSmart Assessor de Investimentos Ltda.

                                Investimentos nos mercados financeiros e de capitais estão sujeitos a riscos de perda igual ou superior ao valor total do capital investido. Os investidores devem obter orientação financeira independente, com base em suas características pessoais, antes de tomar uma decisão de investimento. A decisão de investimento e avaliação dos riscos são de exclusiva responsabilidade do cliente.

                                Rentabilidade passada não é garantia de rentabilidade futura em nenhuma modalidade de investimento. Os riscos da operação com títulos de renda fixa estão na capacidade de o emissor (governo, em se tratando de títulos públicos, empresa ou instituições financeiras, em se tratando de títulos privados) honrar a dívida (risco de crédito); na impossibilidade de venda do título ou na ausência de investidores interessados em adquiri-lo (risco de liquidez); e na possibilidade de variação da taxa de juros e dos indexadores (risco de mercado).

                                Em se tratando de título privado, o risco de mercado inclui, ainda, o chamado prêmio do risco, que é aquele inerente à capacidade de pagamento da emissora do título. CRI, CRA, FIDC e Debêntures não contam com a garantia do Fundo Garantidor de Crédito (FGC). Apesar de ser classificado como produto de renda fixa, há risco de perda da totalidade ou parte do valor investido. CDB, LC, LCI e LCA contam com a garantia do FGC, conforme políticas, regras e limites próprios. Para maiores informações, acesse <a href="www.fgc.org.br" style={{ color: '#9966ff' }}>www.fgc.org.br</a>. Muito importante a adequada compreensão da natureza, forma de rentabilidade e riscos dos títulos de renda fixa antes da sua aquisição.

                                Fundos de Investimento em geral não contam com garantia do seu administrador, do gestor, de qualquer mecanismo de seguro ou do FGC. A aplicação em Fundos de Investimento Imobiliários (FII) é recomendada para o perfil moderado ou agressivo. Há

                                baixa liquidez das cotas de FII no cenário brasileiro. Ao considerar a aquisição de cotas de Fundos de Investimento, potenciais investidores deverão realizar sua própria análise e avaliação sobre o Fundo em questão. Leia o prospecto, o regulamento, o formulário de informações complementares e a lâmina de informações essenciais, quando disponíveis, antes de investir em qualquer fundo. Indica-se leitura cuidadosa da seção “Fatores de Risco”, a qual contém a descrição de certos riscos que podem afetar de maneira adversa o investimento do fundo. O objetivo do fundo não caracteriza compromisso ou garantia por parte do administrador de que o mesmo será atingido. Ainda que o gestor da carteira do fundo mantenha sistema de gerenciamento de riscos, não há garantia de completa eliminação de perdas patrimoniais para o fundo e para o investidor.

                                O investimento em ações e Certificados de Operações Estruturadas (COE) são de alto risco. Na realização de operações com derivativos existe a possibilidade de perdas superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais.

                                Caso se trate de material publicitário, este pode não levar em consideração objetivos de investimento, situação financeira ou necessidades específicas do perfil de cada investidor. Eventuais informações publicitárias que possam estar contidas nessa mensagem têm caráter meramente informativo, não constituindo e nem devendo ser interpretada como solicitação de compra ou venda, oferta ou recomendação de qualquer ativo financeiro ou investimento, tampouco como sugestão de alocação ou adoção de estratégias por parte dos destinatários. Portanto, em caso de material publicitário, os ativos, operações, fundos e/ou instrumentos financeiros discutidos podem não ser adequados para todos os investidores. Em caso de material publicitário, os dados mencionados neste material foram extraídos de fonte externa e podem apresentar distorções, não se comprometendo a InvestSmart Assessor de Investimento Ltda com a veracidade das informações, tampouco é permitida sua reprodução ou redistribuição para qualquer pessoa, no todo ou em parte, qualquer que seja o propósito, sem o prévio consentimento por escrito da InvestSmart.

                                Para informações e dúvidas, favor contatar seu assessor de investimento.

                                Para reclamações, favor contatar a Ouvidoria da XP Investimentos no telefone nº 0800-722-3730.
                              </p>
                            </table>
                          )
                        }
                      </div>
                    </div>

                  </div>
                  <div class="btns-opt">
                    <div class="col-12 pr-2 pl-0">
                      <span class="copy-signature bg-success" onClick={copyElement}>
                        <div>Copie a sua assinatura</div>
                        <div style={{ fontSize: 12, position: 'absolute', top: 56 }}></div>
                      </span>
                    </div>

                  </div>
                  <div class="btns-opt-social">
                    <a style={{ color: 'black' }} onClick={goOutlook}>Adicione ao Outlook
                      <img src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/outlook-icon.svg" alt="Adicione ao Outlook" />
                    </a>
                  </div>
                  {
                    codeToCopy ? (
                      <textarea
                        id="textarea-copy"
                        style={{
                          backgroundColor: 'black',
                          width: '100%',
                          padding: 10,
                          height: 200,
                          borderRadius: 5,
                          borderColor: 'gray',
                          fontSize: 12,
                          color: 'gray'
                        }} value={codeToCopy} />
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-layout-2">
          <div class="footer-container-2">
            <div class="copyright-row" id="copyright-line">
              <span class="copyright-text">Copyright ©  InvestSmart, Inc. Desenvolvimento & IT</span>
            </div>
          </div>
        </div>
      </div>

      <Footer />


    </>
  )
}

export default StepThree;