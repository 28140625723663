import React, {useEffect, useState, } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import img1 from '../img/faq-1.png';
import img2 from '../img/faq-2.png';
import img3 from '../img/faq-3.png';
import img4 from '../img/faq-4.png';
import img5 from '../img/faq-5.png';
import img6 from '../img/faq-6.png';
import img7 from '../img/faq-7-mobile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars, faBriefcase, faEnvelope, faFont,
  faImages, faLink, faMapMarkerAlt, faPhoneAlt, faRedoAlt, faTimes, faGlobe, faVoicemail
} from '@fortawesome/free-solid-svg-icons'

function Footer() {
    function openFaqs() {
        document.getElementById('faqs-page').style.display = "flex";
        
      };
      function closingFaqs() {
        document.getElementById('faqs-page').style.display = "none";
      };
    
return(
<>
    <footer>
    <div className="faqs-btn" onClick={openFaqs}>
      <span className="faqs-ballon">FAQs</span>
    </div>
    </footer>
   

    <div class="faqs-layout" id="faqs-page">
      <div class="faqs-content">
          <div class="faqs-content-header">
              <h2>Perguntas Frequentes</h2>
          </div>
          <div class="faqs-content-body">               
              <h3>Como uso essa ferramenta?</h3>
              <ul>
                  <li class="stepitems"> 
                      <span> Na página inicial, selecione a assinatura de acordo com o seu perfil e emaiL. Cada assesor de investimentos tem pelo menos 2 emails. Para cada e-mail, uma assinatura própria. <br/>Clique em Gerar assinatura após escolher o seu perfil e o seu email correto. 
                      </span>
                      <span>

                      </span>
                      <img src={img2}/>
                  </li>
                  <li class="stepitems"> 
                      <span> No editor, na primeira aba, preecha seus dados corretamentes. Os campos Nome, Sobrenome, Telefone Celular e Setor, são campos obrigatórios. 
                      </span>
                      <span>

                      </span>
                      <img src={img3}/>
                  </li>
                 
                  <li class="stepitems">
                      <span>Clique na terceira aba para definir sua foto.</span>
                      <img src={img4}/>
                  </li>
                  <li class="stepitems">
                      <span>Depois que acabar de editar sua assinatura, clique no botão “Finalizar assinatura” para confirmar seus dados e desbloquear seu uso.</span>
                      <img src={img5}/>

                  </li>
              </ul>

              <hr class="faqs-divisor"/>

              <h3>Finalizado a criação da sua assinatura</h3>
              <p>Depois do preenchimento correto dos seus dados para criação da assinatura, a imagem a seguir é um <strong>exemplo </strong> de como ficará sua assinatura.</p>
              <img src={img6}/>
              <p>Lembrando que você poderá copiar o HMTL da sua assinatura, como também adicionar ao Outlook. Na próxima seção, explicaremos como efetuar esse passo.</p>

            

              <hr class="faqs-divisor"/>

              <h3>Como adiciono minha nova assinatura de e-mail ao Outlook?</h3>
              <ul>
                  <li class="stepitems">
                      <span>Entre na sua conta do Outlook e selecione Início e Novo e-mail.</span>
                  </li>
                  <li class="stepitems">
                      <span>Clique na aba de mensagens.</span>
                  </li>
                  <li class="stepitems">
                      <span>Vá para a seção Incluir grupo  Assinatura - Assinaturas.</span>
                  </li>
                  <li class="stepitems">
                      <span>Na seção 'Escolher assinatura padrão', veja se está na conta certa que deseja associar com essa assinatura no menu suspenso de contas de e-mail.</span>
                  </li>
                  <li class="stepitems">
                      <span>Na seção 'Selecionar assinatura para editar', clique no botão 'Novo' e nomeie a sua assinatura.</span>
                  </li>
                  <li class="stepitems">
                      <span>Na seção 'Editar assinatura', cole sua assinatura da InvestSmart e selecione OK.</span>
                  </li>
              </ul>

              <hr class="faqs-divisor"/>

              <h3>Estou no celular. Como posso criar minha assinatura de e-mail?</h3>
              <p>O gerador de modelo de assinatura de e-mail da InvestSmart atualmente só funciona no desktop. Isso nos ajuda a garantir que você terá a melhor experiência possível. Se estiver em um dispositivo móvel, siga as instruções para enviar a ferramenta por e-mail para você mesmo para usá-la no desktop mais tarde.</p>

              <hr class="faqs-divisor"/>

              <h3>Por que a minha assinatura não está como eu queria?</h3>
              <p>Se sua assinatura não está como você queria, não se preocupe. É fácil corrigir. Veja como pode corrigir:</p>
              <ul>
                  <li class="stepitems">
                      <span>Tente usar um navegador diferente para criar sua assinatura. Alguns navegadores não copiam o formato junto com o texto e isso muda a cada atualização dos navegadores.</span>
                  </li>
              </ul>
              <hr class="faqs-divisor"/>

          </div>
      </div>
      <span class="close-btn" id="close-faqspage" onClick={closingFaqs}>
          <FontAwesomeIcon icon={faTimes} />
      </span>
  </div>
    </>
)

}

export default Footer;

