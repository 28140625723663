import React, {useState, Component} from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import { faArrowLeft, faBars, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



function StepTwo() {
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [cargo, setCargo] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [site, setSite] = useState('');
  const [cellcomercial, setCellcomercial] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [endereco1, setEndereco1] = useState('');
  const [endereco2, setEndereco2] = useState('');
  const [endereco3, setEndereco3] = useState('');
  const [endereco4, setEndereco4] = useState('');


  return (
    <div class="full-page-2">
      <div class="top-menu" id="menu-navbar">
        <span class="logo-navbar"><img alt="" src="https://cdn2.hubspot.net/hubfs/53/tools/hubspot-tools.svg" /></span>
        <span class="menu-navbar-btn" id="menu-btn-mobile"><i><FontAwesomeIcon icon={faBars} /></i></span>
      </div>
      <div class="content-layout-2">
        <div class="header-layout">
          <div class="header-content">
            <span class="voltar-editor"><i><FontAwesomeIcon icon={faArrowLeft} /></i>Voltar ao editor</span>
          </div>
        </div>
        <div class="body-layout-2">
          <div class="content-body-2">
            <div class="step-row">
              <div class="step-item" style={{ marginRight: 50 }}>
                <span id="step-1">1</span>
                <p>criar</p>
              </div>
              <hr class="step-separator active" />
              <div class="step-item" style={{ marginRight: 50, marginLeft: 50 }}>
                <span id="step-2">2</span>
                <p>confirmar</p>
              </div>
              <hr class="step-separator" />
              <div class="step-item" style={{ marginLeft: 50 }}>
                <span id="step-3">3</span>
                <p>baixar</p>
              </div>
            </div>
            <div class="form-body-2">
              <div class="header-body-2">
                <h2 class="title-header-2">Sua nova assinatura já está pronta! Simplesmente responda às perguntas abaixo para encerrar.</h2>
              </div>
              <div class="content-form">
                <form class="form-step-2">
                  <div class="form-container">
                    <div class="sc-kafWEX form-row">
                      <span class="sc-bwzfXH form-item">
                        <span class="sc-htpNat form-label">Sobrenome</span>
                        <input
                        name="sobrenome" 
                        type="text" 
                        placeholder="Sobrenome"
                        onChange={(event) => setSobrenome(event.target.value)}
                        required
                        />
                        <div class="sc-ifAKCX TGEJZ">
                          <div class="sc-bdVaJa jIVhXq">
                            <span class="isvg loaded _2236daa4-8120-bf35-567f-8b340361ce71"></span>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="sc-kafWEX form-row">
                      <span class="sc-bwzfXH form-item">
                        <span class="sc-htpNat form-label">Endereço de Email</span>
                        <input
                        name="email"
                        type="text"
                        placeholder="Endereço de Email"
                        onChange={(event) => setEmail(event.target.value)}
                        required
                        />
                        <div class="sc-ifAKCX TGEJZ">
                          <div class="sc-bdVaJa jIVhXq">
                            <span class="isvg loaded _2236daa4-8120-bf35-567f-8b340361ce71"></span>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="sc-kafWEX form-row">
                      <span class="sc-bwzfXH form-item">
                        <span class="sc-htpNat form-label">Nome da empresa</span>
                        <input
                        name="company"
                        type="text"
                        placeholder="Nome da empresa"
                        onChange={(event) => setCompany(event.target.value)}
                        required
                        />
                        <div class="sc-ifAKCX TGEJZ">
                          <div class="sc-bdVaJa jIVhXq">
                            <span class="isvg loaded _2236daa4-8120-bf35-567f-8b340361ce71"></span>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="sc-kafWEX form-row">
                      <span class="sc-bwzfXH form-item">
                        <span class="sc-htpNat form-label">URL do Site</span>
                        <input
                        name="website"
                        type="text"
                        placeholder="URL do Site"
                        onChange={(event) => setSite(event.target.value)}
                        required
                        />
                        <div class="sc-ifAKCX TGEJZ">
                          <div class="sc-bdVaJa jIVhXq">
                            <span class="isvg loaded _2236daa4-8120-bf35-567f-8b340361ce71"></span>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="sc-kafWEX form-row">
                      <span class="sc-bwzfXH form-item">
                        <span class="sc-htpNat form-label">Telefone Celular</span>
                        <input name="comercialphone"
                        type="text"
                        placeholder="Telefone Celular"
                        onChange={(event) => setCellphone(event.target.value)}
                        required
                        />
                        <div class="sc-ifAKCX TGEJZ">
                          <div class="sc-bdVaJa jIVhXq">
                            <span class="isvg loaded _62c1f4f4-52db-5346-d8b0-cdf68bafdd0a"></span>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="form-container">
                    <div class="form-row">
                      <div class="form-selector-header">Quantos funcionários trabalham com você?</div>
                      <div class="form-selector-qtd">
                        <span class="form-selector-info"></span>
                        <div class="form-selector">
                          <div class="form-selector-line"></div>
                          <div class="form-selector-fulfilled" style={{ width: 0 }}></div>
                          <div class="form-selector-pointer" style={{ cursor: 'pointer', transform: 'translate(0%, -50%) translate3d(0px, 0px, 0px)', touchAction: 'pan-y', userSelect: 'none' }}>
                            <div class="form-selector-info-qtd">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>


              </div>
              <div class="terms-and-conditions">
                <p>Temos um compromisso com a sua privacidade. A HubSpot usa as informações que você nos fornece para contatá-lo a respeito de nosso conteúdo, nossos produtos e serviços relevantes. Você pode cancelar a assinatura dessas comunicações a qualquer momento. Para ter mais informações, consulte a nossa Política de Privacidade</p>
              </div>
              <div class="confirm-signature">
                <Link to={{
                pathname:"/passo-tres",
                state: {nome, sobrenome, cargo, departamento, email, cellphone, company, site}
                }}><span class="confirm-btn">Confirmar Assinatura</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-layout-2">
        <div class="footer-container-2">
          <div class="footer-logo-layout-2">
            <span class="logo"><img alt="" src="https://cdn2.hubspot.net/hubfs/53/tools/hubspot-tools.svg" /></span>
          </div>
          <div class="copyright-row" id="copyright-line">
            <span class="copyright-text">Copyright © 2023 InvestSmart, Inc. Desenvolvimento & IT</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepTwo;