import {useRef} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import './components/home.css';


import StepOne from './screens/stepOne';
import StepTwo from './screens/stepTwo';
import StepThree from './screens/stepThree';
import Home from './screens/home';
import NoMobile from "./components/NoMobile";
import StepOneBe from './screens/stepOneBe';
import Imoveis from './screens/Imoveis';
import Besmart from './screens/besmart';
import InvestSmart from './screens/investsmart';
import IsAssessoria from './screens/isassessoria';
import AssessoriaIs from './screens/assessoriais';
import ReactGa from 'react-ga'; 

// import AlertTemplate from "react-alert-template-basic";
const TRACKING_ID = "G-1JF4YLZG1H";
ReactGa.initialize(TRACKING_ID);

function App() {

  function openFaqs() {
    document.getElementById('faqs-page').style.display = "flex";
    
  };

  

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/passo-tres">
            <StepThree />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>  
          <Route path="/StepOne">
            <StepOne />
          </Route>
          <Route path="/AssessoriaIs">
            <AssessoriaIs />
          </Route>
          <Route path="/StepOneBe">
            <StepOneBe />
          </Route>
          <Route path="/Imoveis">
            <Imoveis />
          </Route>
          <Route path="/Besmart">
            <Besmart />
          </Route>
          <Route path="/InvestSmart">
            <InvestSmart />
          </Route>
          <Route path="/IsAssessoria">
            <IsAssessoria />
          </Route>
         
          <Route path="Home">
            <Home />
          </Route>
        </Switch>
        <NoMobile/>
      
      </div>
    </Router>
  );
}

export default App;
