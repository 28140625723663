import React, {useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBars, faEnvelope, faGlobe, faLink, faMapMarkerAlt, faPhoneAlt, faVoicemail, faTimes } from '@fortawesome/free-solid-svg-icons'

import './styles.css';
import './home.css';

import img1 from '../img/1.png';
import img2 from '../img/2.png';
import img3 from '../img/3.png';
import img4 from '../img/4.png';
import img5 from '../img/5.png';

const Mobile = () => {

    const mobile = useRef(null);

    function openFaqsMobile() {
        document.getElementById('faqs-page-mobile').style.display = "flex";
        console.log(mobile)
        mobile.current.scrollIntoView();
    };

   

    return (
        <>
        <div id="noMobile" className="container-wrapper gzLGZz bg-escuro">
            <div className="sc-bMVAic ctrYKo">
                <img
                src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/mobile-illustration.svg"
                alt="Using a desktop to create one of several email signature designs in HubSpot's Email Signature Generator"
                class="sc-hORach ibKKBG"
                style={{width: '250px'}}
                />

                <span className="sc-iujRgT fGoFae">Acesse nosso site pelo desktop para ter a melhor experiência com o gerador de modelos de assinatura de e-mail</span>
                <div class="sc-bAeIUo cjHcCP">Ou</div>

                <a 
                value="mailto:?subject=Gerador de modelo de assinatura de e-mail da InvestSmart&amp;body=https://assinatura.hubsmart.app.br"
                href="mailto:?subject=Assinatura&amp;body=assinatura.investsmart"
                target="_blank" rel="noopener noreferrer" 
                class="sc-jWBwVP gqCMAi">
                    Envie por e-mail para ver depois
                </a>

                <div class="sc-GMQeP kqiwji">
                    <div class="sc-cLQEGU dVYhGu">
                        <span class="sc-hSdWYo XohZS copyright">Copyright  InvestSmart, Inc. Desenvolvimento & IT
                        </span>
                    </div>
                </div>
            </div>
        </div>

      
        </>
    );
}

export default Mobile;