import React, { useEffect, useState } from "react";

import templateBankRio from '../img/template-besmart-2.png';
import templateBnkRio from '../img/template-investsmart-2.png';
import templateInvestMart from '../img/template-investsmart-xp-2.png';
import templateImoveis from '../img/template-imoveis.png';

import templatebankrio from '../img/template-besmart-logo.png';
import templateinvestsmart from '../img/template-investsmart-logo.png';
import templatebnkrio from '../img/template-investsmart-logo.png';
import templatebesmart from '../img/template-besmart-logo.png';
import templateimoveis from '../img/BeSmart_Logos_imoveis.png';
import templateis from '../img/IS_Assessoria.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle
  } from '@fortawesome/free-solid-svg-icons'

import '../App.css';

const Templates = ({setTemplate}) => {

    const [choice, setChoice] = useState(0)

    //Função para mudar a cor dos templates
    function alterar () {
        var i = 0;
        var companyID = document.getElementById('template-bankrio').getAttribute('id');
        var x = document.querySelector('.item-modelo.active').getAttribute('id');
        var y = document.querySelector('.body-layout').setAttribute('id', x);
        var firstColor;
        var secondColor;
        var treeColor;
        var aplicarTemplate = document.querySelectorAll('.item-modelo');
        var applyTemplate = function () {
            companyID = this.getAttribute('id');
            console.log(companyID)
            firstColor = getComputedStyle(document.getElementById(`${companyID}`)).getPropertyValue('--color1');
            secondColor = getComputedStyle(document.getElementById(`${companyID}`)).getPropertyValue('--color2');
            treeColor = getComputedStyle(document.getElementById(`${companyID}`)).getPropertyValue('--color3');
            if (companyID == 'template-bankrio') {

                console.log(companyID)
                

                //URLS
                /*
                document.getElementById('glob-link').setAttribute('href', "https://www.bankrio.com.br/")
                document.getElementById('face-link').setAttribute('href', "https://www.facebook.com/bankriofinancial")
                document.getElementById('insta-link').setAttribute('href', "https://www.instagram.com/bankriofinancialgroup/")
                document.getElementById('linke-link').setAttribute('href', "https://www.linkedin.com/company/bankrio")
                */
                

                // Para aparecer os icones
                document.getElementById('glob').style.display ="block";
                document.getElementById('face').style.display ="block";
                document.getElementById('linke').style.display ="block";
                document.getElementById('insta').style.display ="block";

                //Aparece o template
                document.querySelector('.company-profile-logo img').setAttribute('src', templatebankrio );
                setChoice(0);

            } else if (companyID == 'template-investsmart') {

                console.log(companyID)
                
                document.getElementById('glob-link').setAttribute('href', "https://investsmart.com.br/")
                document.getElementById('face-link').setAttribute('href', "https://www.facebook.com/InvestSmartXP")
                document.getElementById('linke-link').setAttribute('href', "https://www.linkedin.com/company/investsmartxp")
                document.getElementById('insta-link').setAttribute('href', "https://www.instagram.com/investsmart.xp/")
                

                // Para aparecer os icones
                document.getElementById('glob').style.display ="block";
                document.getElementById('face').style.display ="block";
                document.getElementById('linke').style.display ="block";
                document.getElementById('insta').style.display ="block";

                //Aparece o template
                document.querySelector('.company-profile-logo img').setAttribute('src', templateinvestsmart);
                setChoice(1);
            } else if (companyID == 'template-imoveis') {

                console.log(companyID)
                

                //URLS
             
                document.getElementById('glob-link').setAttribute('href', "https://www.besmartimoveis.com.br/")
                document.getElementById('face-link').setAttribute('href', "  https://www.facebook.com/besmartimoveis")
                document.getElementById('insta-link').setAttribute('href', "https://www.instagram.com/be.smart_imoveis/")
                document.getElementById('linke-link').setAttribute('href', "https://www.linkedin.com/company/besmartimoveis")
                
                

                // Para aparecer os icones
                document.getElementById('glob').style.display ="block";
                document.getElementById('face').style.display ="block";
                document.getElementById('linke').style.display ="block";
                document.getElementById('insta').style.display ="block";

                //Aparece o template
                document.querySelector('.company-profile-logo img').setAttribute('src', templateimoveis);
                setChoice(3);
            }else if (companyID == 'template-isassessoria') {

                console.log(companyID)
                
                document.getElementById('glob-link').setAttribute('href', "https://investsmart.com.br/")
                document.getElementById('face-link').setAttribute('href', "https://www.facebook.com/InvestSmartXP")
                document.getElementById('linke-link').setAttribute('href', "https://www.linkedin.com/company/investsmartxp")
                document.getElementById('insta-link').setAttribute('href', "https://www.instagram.com/investsmart.xp/")
                

                // Para aparecer os icones
                document.getElementById('glob').style.display ="block";
                document.getElementById('face').style.display ="block";
                document.getElementById('linke').style.display ="block";
                document.getElementById('insta').style.display ="block";

                //Aparece o template
                document.querySelector('.company-profile-logo img').setAttribute('src', templateis);
                setChoice(4);
            } else {

                // Para desaparecer os icones

                const x = document.querySelector('.company-profile-logo img');
                x.style.width = '200px';

                document.getElementById('glob').style.display ="block";
                document.getElementById('face').style.display ="block";
                document.getElementById('linke').style.display ="block";
                document.getElementById('insta').style.display ="block";

                //Aparece o template
                document.querySelector('.company-profile-logo img').setAttribute('src', templatebnkrio);
                setChoice(2);
            }
            var selectBody = document.querySelector('body');
            selectBody.style.setProperty('--company-color', firstColor);
            selectBody.style.setProperty('--company-2nd-color', secondColor);
            selectBody.style.setProperty('--company-3nd-color', treeColor);
            if (companyID == 'template-investsmart') {
                document.querySelector('.company-slogan h3').innerHTML = 'Pensou em investimentos, pense InvestSmart';
            }
            else if (companyID == 'template-bnkrio') {
                
            }
            else {
                document.querySelector('.company-slogan h3').innerHTML = '' //Aqui se quiser criar uma frase pra entrar no meio da logo da assinatura e redes sociais
            };
        };


        for (i = 0; i < aplicarTemplate.length; i++) {
            aplicarTemplate[i].addEventListener('click', applyTemplate);
        };
    }

    //Função para mudar a cor ao selecionar os templates (thumbs) no menu esquerdo 

  
    function selectModel(flag) {
        setTemplate(flag)

        if (flag == 1) {
            document.getElementById('template-investsmart').classList.add('active');
            document.getElementById('template-bankrio').classList.remove('active');
        } else if (flag == 2) {
            document.getElementById('template-bnkrio').classList.add('active');
            document.getElementById('template-investsmart').classList.remove('active');
            document.getElementById('template-bankrio').classList.remove('active');
            
        } else if (flag == 3)
        {
            document.getElementById('template-imoveis').classList.add('active');
            document.getElementById('template-bnkrio').classList.remove('active');
            document.getElementById('template-investsmart').classList.remove('active');
            document.getElementById('template-bankrio').classList.remove('active');
        }else if (flag == 4)
        {
            document.getElementById('template-isassessoria').classList.add('active');
            document.getElementById('template-imoveis').classList.remove('active');
            document.getElementById('template-bnkrio').classList.remove('active');
            document.getElementById('template-investsmart').classList.remove('active');
            document.getElementById('template-bankrio').classList.remove('active');
        }


        var i
        var selecionarModelo = document.getElementsByClassName('item-modelo');
        var altTemplate = function () {
            if (selecionarModelo.length < 5) {
                document.querySelector('.item-modelo.active').classList.remove('active');
                this.classList.add('active');
                var elementActive = document.querySelector('.item-modelo.active').innerHTML;
            };
        };
        for (i = 0; i < selecionarModelo.length; i++) {
            selecionarModelo[i].addEventListener('click', altTemplate);
        };
    }

    // Função fim

    useEffect(() => {
        alterar();
    })

    return (
      <div class="content-bar" id="templatesinicial">
      <div class="title-bar">
          <h2>Selecione a Empresa</h2>
      </div>
      <div class="createhubs-bar">
          <div class="subtitle-bar" style={{display:"none"}}>
              <h3>Criado por Tecnologia & Processos - Cadu Rocha </h3>
              <span class="select-createhubs"><span class="btn-selector"></span></span>
          </div>
          <div class="select-modelos">
              <div class="modelo-1" id="bankrio">
                  <h4 class="item-title">Assinatura Be.Smart</h4>
                  <div class="item-modelo active" id="template-bankrio" onClick={() => {selectModel(0)}}>
                      <img src={templateBankRio}/>
                      {
                          choice === 0 ? (<span class="check-btn"><i><FontAwesomeIcon icon={faCheckCircle}/></i></span>) : null
                      }
                  </div>
                  <small id="emailHelp" class="form-text text-white">@besmart.is</small>
                  <small id="emailHelp" class="form-text text-white">Modelo Assinatura para o comercial da Be.Smart</small>

              </div>
              <hr></hr>
              <div class="modelo-4" id="imoveis">
                  <h4 class="item-title">Assinatura Be.Smart Imóveis </h4>
                  <div class="item-modelo" id="template-imoveis" onClick={() => {selectModel(3)}}>
                      <img src={templateImoveis}/>
                      {
                          choice === 3 ? (<span class="check-btn"><i><FontAwesomeIcon icon={faCheckCircle}/></i></span>) : null
                      }
                  </div>
                  <small id="emailHelp" class="form-text text-white">@besmartimoveis.com.br</small>
                  <small id="emailHelp" class="form-text text-white">Modelo Assinatura para o time da Be.Smart Imóveis</small>
              </div>
              <hr></hr>
              <div class="modelo-2" id="investsmart">
                  <h4 class="item-title">InvestSmartXP Assessor  -  Comunicação XP</h4> 
                 
                  <div class="item-modelo" id="template-investsmart" onClick={() => {selectModel(1)}}>
                      <img src={templateInvestMart}/>
                      {
                          choice === 1 ? (<span class="check-btn"><i><FontAwesomeIcon icon={faCheckCircle}/></i></span>) : null
                      }
                  </div>
                  <small id="emailHelp" class="form-text text-white">@assessoriainvestsmart.com.br & Investsmart.com.br</small>
                  <small id="emailHelp" class="form-text text-white">Se você é assessor de investimento, deve utilizar somente este modelo de email</small>
                  
              </div>
              <hr></hr>
              <div class="modelo-3" id="bnkrio">
                  <h4 class="item-title">Assinatura InvestSmart  Backoffice </h4>
                  <div class="item-modelo" id="template-bnkrio" onClick={() => {selectModel(2)}}>
                      <img src={templateBnkRio}/>
                      {
                          choice === 2 ? (<span class="check-btn"><i><FontAwesomeIcon icon={faCheckCircle}/></i></span>) : null
                      }
                  </div>
                  <small id="emailHelp" class="form-text text-white">@investsmart.com.br</small>
                  <small id="emailHelp" class="form-text text-white">Se você trabalha no BackOffice da InvestSmart</small>
              </div>
              <hr></hr>
              <div class="modelo-5" id="isassessoria">
                  <h4 class="item-title">Assinatura IS  </h4>
                  <div class="item-modelo" id="template-isassessoria" onClick={() => {selectModel(4)}}>
                  <img src={templateInvestMart}/>
                      {
                          choice === 4 ? (<span class="check-btn"><i><FontAwesomeIcon icon={faCheckCircle}/></i></span>) : null
                      }
                  </div>
                  <small id="emailHelp" class="form-text text-white">@isassessoria.com.br</small>
                  <small id="emailHelp" class="form-text text-white">Texto de apoio</small>
              </div>
          </div>
          <span class="copyright"><p></p></span>
      </div>
  </div>
    );
}

export default Templates;