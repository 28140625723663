import React, { useState } from "react";
import './styles.css';
import ReactCrop from 'react-image-crop';
import Modal from 'react-modal';
import Cropper from "react-cropper";
import placeholdImage from '../img/placeholder-image@2x.png';
import "cropperjs/dist/cropper.css";

const ImagensPerson = ({ setProfile }) => {

  const [src, selectFile] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [image, setImage] = useState(null)

  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();

  const handleFileChange = e => {
    var file = e.target.files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      selectFile(reader.result);
      setProfile(reader.result);
      setEditOpen(true)
    }

    if (file) {
      // reader.readAsDataURL(file);
      resizeImage(file, 1000).then(resizedDataUrl => {
        selectFile(resizedDataUrl);
        setProfile(resizedDataUrl);
        setEditOpen(true);
      });
    } else {
      setProfile(placeholdImage);
    }

  }

  const resizeImage = (file, maxSize) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          let width = img.width;
          let height = img.height;
          
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL('image/jpeg', 0.9));
        };
      };
    });
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function getRoundedCanvas(sourceCanvas) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      let croppedCanvas = cropper.getCroppedCanvas();
      let roundedCanvas = getRoundedCanvas(croppedCanvas);
      selectFile(roundedCanvas.toDataURL());
      setProfile(roundedCanvas.toDataURL());
      setEditOpen(false)
    }
  };

  const closeModal = () => {
    setEditOpen(false)
    setProfile(placeholdImage)
  }

  return (

    <div hidden class="content-bar" id="img-person">
      <div class="title-bar pt-3 pl-2">
        <h2>Carregue suas imagens personalizadas</h2>
      </div>

      <Modal
        isOpen={editOpen}
        style={customStyles}
      >
        <div style={{ height: 500, width: 500 }}>
          <Cropper
            style={{ height: 400, width: "100%" }}
            aspectRatio={1}
            preview=".img-preview"
            src={src}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <button className="btn btn-danger my-2 mx-2" style={{ float: "right" }} onClick={getCropData}>
            Salvar
          </button>

          <button className="btn btn-warning my-2 mx-2" style={{ float: "right" }} onClick={closeModal}>
            Fechar
          </button>
        </div>

      </Modal>

      <div id="imgProfile" className="mb-4 ">
        <div className="div-infos">
          <label for="imgProfile2" className="span-infos ajuste-group-inputs bg-success">Imagem do perfil</label>
          <input
            accept='image/*'
            id="imgProfile2"
            type="file"
            class="input-infos"
            onChange={handleFileChange}
            required />
        </div>
      </div>

      <div class="footer-bar text-center">
        <span class="copyright-text">Copyright ©  InvestSmart, Inc. Desenvolvimento & Processos.</span>
      </div>
    </div>
  );
}

export default ImagensPerson;