import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Alerta() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <>
      <div class="mt-40">
      <Alert variant="warning" onClose={() => setShow(true)} dismissible>
        <p class="text-small text-left"> <i><FontAwesomeIcon icon={faExclamationTriangle} /></i>   É proibida a utilização dessa assinatura pelos Assessores de Investimentos.    </p>
      </Alert>
      </div></>
    );
  }
  return <Button onClick={() => setShow(false)}>Alerta</Button>;
}

export default Alerta;