import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { positions, Provider } from 'react-alert';
import ReactGa from 'react-ga'; 

// import AlertTemplate from "react-alert-template-basic";
const TRACKING_ID = "G-1JF4YLZG1H";
ReactGa.initialize(TRACKING_ID);

const options = {
  timeout: 3000,
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={{
      backgroundColor:
        options.type === 'info' ? "rgb(30, 188, 165)" :
        options.type === 'success' ? "rgb(30, 188, 165)" :
        options.type === 'error' ? "rgb(188, 50, 50)" : "white",
      color: 'white',
      padding: 10,
      borderRadius: 10,
      marginTop: 20,
      right: 20,
      position: 'absolute'
    }}>
    {message}
  </div>
)


ReactDOM.render(
  <React.StrictMode>
    <Provider template={AlertTemplate} {...options}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
