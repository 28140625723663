import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars, faBriefcase, faEnvelope, faFont,
  faImages, faLink, faMapMarkerAlt, faPhoneAlt, faRedoAlt, faTimes, faGlobe, faVoicemail
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import TemplatesBe from '../components/TemplatesBe';
import Info from '../components/Info';
import ImagensPerson from '../components/ImagensPerson';
import { useAlert, positions } from "react-alert";
import LogoBNK from '../img/bnkrio-white.png';
import LogoBNKRio from '../img/bankrio-white.png';
import LogoBesmart from '../img/besmart-logo.png';


import InvestSmart from '../img/investsmart-white-infinito.png';
import InvestSmartXP from '../img/investsmart-white.png';
import templatebankrio from '../img/template-bankrio-logo.png';
import templatebesmart from '../img/template-besmart-logo.png';
import templateinvestsmart from '../img/template-investsmart-logo.png';
import templateinvestsmart2 from '../img/template-investsmart-logo-nova.png';
import templatebnkrio from '../img/template-bnkrio-logo.png';
import templateimoveis from '../img/BeSmart_Logos_imoveis.png';
import templateis from '../img/IS_Assessoria.png'

import placeholdImage from '../img/placeholder-image-icon.png';
import G20 from '../img/Selo_n1_G20.png';
import Certificado from '../img/certificado.png';

import img1 from '../img/1.png';
import img2 from '../img/2.png';
import img3 from '../img/3.png';
import img4 from '../img/4.png';
import img5 from '../img/5.png';


import '../components/styles.css';
import StepThree from './stepThree';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';


function StepOneBe() {

  const [nome, setNome] = useState(null);
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [cargo, setCargo] = useState('');
  const [cellcomercial, setCellcomercial] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [endereco1, setEndereco1] = useState('');
  const [url, setURL] = useState('');
  const [profile, setProfile] = useState(placeholdImage);
  const [templatebe, setTemplate] = useState(0);
  var error = false;
  var aviso = "Preencha o(s) campo(s): ";
  var i;
  const alert = useAlert();
  let history = useHistory();

  useEffect(() => {
    if(templatebe === 1){
      setCargo('Assessor de Investimentos')
    } else {
      setCargo('')
    }
  }, [templatebe])

  function dados(dado, label) {
    if (!dado) {
      error = true

      document.getElementById("templatesinicial").setAttribute('hidden', true);
      document.getElementById("info").setAttribute('hidden', true);
      document.getElementById("img-person").setAttribute('hidden', true);

      document.getElementById("info").removeAttribute('hidden');
      document.getElementById("images").classList.remove('active');
      document.getElementById("info-form").classList.add('active');
      document.getElementById("templates").classList.remove('active');

      let verificarDado = document.getElementById(label);
      verificarDado.classList.add('error-input');

      aviso += '' + label + ', ';
      
    }
  }

  function verificarDados () {
    error = false;
    dados(nome, "Nome")
    dados(sobrenome, "Sobrenome")
    dados(email, "Email")
    dados(cargo, "Cargo")
    dados(cellphone, "Celular")

    if (!error) {
      var templateID = document.querySelector('.body-layout').getAttribute('id');
      console.log(templateID);
      history.push(
        {
          pathname: "/passo-tres",
          state:{ profile, nome, sobrenome, email, cargo, cellcomercial, cellphone, endereco1, url, templatebe, templateID}
        }
      );
    } else {
      aviso = aviso.slice(0, -2)
      alert.error(aviso, {timeout: 1500})
      aviso = "Preencha o(s) campo(s): ";
    }
  }

  function clear() {
    setNome('João');
    setSobrenome('da Silva');
    setEmail('assinatura@digital.com');
    setCargo('Setor');
    setCellcomercial('(99) 9999-9999');
    setCellphone('(99) 99999-9999');
    setEndereco1('Av. Almirante Barroso');
    setURL('https://sualandingpage.com.br');
    setProfile(placeholdImage);

    document.getElementById("Nome").value = ""
    document.getElementById("Sobrenome").value = ""
    document.getElementById("Email").value = ""
    document.getElementById("Cargo").value = ""
    document.getElementById("telComercial").value = ""
    document.getElementById("Celular").value = ""
    document.getElementById("endereco").value = ""
    document.getElementById("url").value = ""
  } 


  // Função para alterar o navbar //

  function AlternarMenuModelos(e) {
    e.preventDefault();

    document.getElementById("templatesinicial").removeAttribute('hidden');
    document.getElementById("info").setAttribute('hidden', true);
    document.getElementById("img-person").setAttribute('hidden', true);
    document.getElementById("images").classList.remove('active');
    document.getElementById("info-form").classList.remove('active');
    document.getElementById("templates").classList.add('active');

  }

  function AlternarMenuText (e) {
    e.preventDefault();
    
    document.getElementById("templatesinicial").setAttribute('hidden', true);
    document.getElementById("info").setAttribute('hidden', true);
    document.getElementById("img-person").setAttribute('hidden', true);

    document.getElementById("info").removeAttribute('hidden');
    document.getElementById("images").classList.remove('active');
    document.getElementById("info-form").classList.add('active');
    document.getElementById("templates").classList.remove('active');
  }

  function AlternarMenuImgs(e) {
    e.preventDefault();

    document.getElementById("templatesinicial").setAttribute('hidden', true);
    document.getElementById("info").setAttribute('hidden', true);

    document.getElementById("img-person").removeAttribute('hidden');
    document.getElementById("images").classList.add('active');
    document.getElementById("info-form").classList.remove('active');
    document.getElementById("templates").classList.remove('active');
  }




  function closingFaqs() {
    document.getElementById('faqs-page').style.display = "none";
  };

  return (
    <>
    <div id="page" class="full-page">
      <div class="top-menu" id="menu-navbar">
        <span class="logo-navbar"><img alt="" src="https://cdn2.hubspot.net/hubfs/53/tools/hubspot-tools.svg" /></span>
        <span class="menu-navbar-btn" id="menu-btn-mobile"><i><FontAwesomeIcon icon={faBars} /></i></span>
      </div>
      <div class="lateral-bar">
        <div class="top-bar-header top-bar-bg-verde row">
          <div class="col-4 painel-top-btn " id="templates" onClick={AlternarMenuModelos}>
            <i><FontAwesomeIcon icon={faBriefcase} /></i>
          </div>
          <div class="col-4 painel-top-btn active" id="info-form" onClick={AlternarMenuText}>
            <i><FontAwesomeIcon icon={faFont} /></i>
          </div>
          <div class="col-4 painel-top-btn" id="images" onClick={AlternarMenuImgs}>
            <i><FontAwesomeIcon icon={faImages} /></i>
          </div>
        </div>

        <TemplatesBe setTemplate={setTemplate}/>


        <Info 
        setNome={setNome}
        setSobrenome={setSobrenome}
        setEmail={setEmail}
        setCargo={setCargo}
        setCellcomercial={setCellcomercial}
        setCellphone={setCellphone}
        setEndereco1={setEndereco1}
        setURL={setURL}
        template={templatebe}
        cargo={cargo}
        />

        <ImagensPerson setProfile={setProfile} />

        

      </div>
        <div class="content-layout bg-roxo">
        <header className="header-layout top-bar-bg-verde">
      <img src={placeholdImage} width={50}/>
      <span className="fs-3 text-white ">Gerador de Assinatura do Email</span>
      </header>
      
      <section className='text-center text-white pre-header '>
        <h2 className='content-body-header' > Assinatura de email para os colaboradores @be.smart</h2>
        <p> Se você é um colaborador que possui um email @besmart.com.br ou @besmartimoveis.com.br você esta no lugar certo.<br/> Acesse o Faq paara saber como preencher e gerar sua assinatura. </p>

      </section>

          <div class="body-layout">
            <div class="window-screen">
              <div class="top-window">
                <span class="btn-close"></span>
                <span class="btn-min"></span>
                <span class="btn-max"></span>
              </div>
              <div class="top-infomessage-window">
                <span class="to-message">Para: <strong style={{ paddingLeft: 5 }}>Seu destinatário</strong></span>
                <span class="subject-message">Assunto: <strong style={{ paddingLeft: 5 }}>Veja minha nova assinatura de e-mail da Be.Smart</strong></span>
              </div>
              <div class="content-window">
                <div class="body-window">
                  <div class="info-member-window">
                    <div class="col-lg-5 col-md-6">
                      <div class="col-4" style={{padding: '0'}}>
                          <div class="profile-img">
                            <img id="cropper-img" className="borderimg" src={profile} />
                          </div>
                      </div>
                      <div class="col-8" style={{paddingLeft: '20px'}}>
                          <div class="profile-name">
                            <h5><strong>{nome || 'João'} {sobrenome || 'da Silva'}</strong></h5>
                          </div>
                          <div class="profile-info">
                              <span class="graduation"><strong>{cargo || 'Setor'}</strong></span>
                          </div>
                      </div>
                    </div>
                    <hr class="vertical"/>
                      
                    <div class="col-lg-7 col-md-6" style={{paddingLeft: '50px'}}>
                      <div class="contact-block">

                          {
                            !nome ? (<span class="phone">{cellphone || '(99) 99999-9999'}<span className="alinhamento-span"> | {cellcomercial || ' (99) 9999-9999'}</span></span>) : null
                          }

                          {
                            nome && cellcomercial.length == 0 ? (<span class="phone">{cellphone || '(99) 99999-9999'}<span hidden> | {cellcomercial || '(99) 9999-9999'}</span></span>) : null
                          }

                          {
                            nome && cellcomercial.length > 0 && cellphone.length > 0 ? (<span class="phone">{cellphone || 'Telefone Celular'}<span className="alinhamento-span"> | {cellcomercial || 'Telefone Comercial'}</span></span>) : null
                          }

                          <span class="mail">{email || 'assinatura@digital.com'}</span>

                          {
                            !nome || url.length > 0 ? (<a target="_blank" style={{color:'inherit'}} href={url || 'https://sualandingpage.com.br'}><span class="extra-description">{url || 'https://sualandingpage.com.br'}</span></a>) : null
                          }

                          {
                            !nome || endereco1.length > 0 ? (<span class="endereco" >{endereco1 || 'Av. Almirante Barroso'}</span>) : null
                          }

                      </div>
                    </div>
                  </div>
               {/*    <hr style={{margin: '40px 0 0'}}/> */}

                  {
                    templatebe === 0 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block">
                          <img src={templatebesmart}/>
                        </div>

                         <div class="company-slogan">
                           <h3 class="mb-0 mt-20 text-left py-2"></h3> 
                        </div> 

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://besmart.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/grupobesmart"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/besmartis/"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/be.smart.is/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                  {
                    templatebe === 1 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block">
                          <img src={templateinvestsmart}/>
                          {/* <div class="company-slogan">
                            <h3 class="mb-0 text-left py-2">Pensou em investimentos, pense InvestSmart</h3>
                          </div> */}
                        </div>

                        <div className="d-flex align-items-center images">
                       {/*    <img src={G20} className="px-4" />
                          <img src={Certificado} /> */}
                        </div>

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://investsmartxp.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/InvestSmartXP"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/investsmartxp"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/investsmart.xp/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }

                  {
                    templatebe === 2 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block">
                          <img src={templateinvestsmart}/>
                        </div>
                        <div hidden class="company-slogan">
                          {/* <h3 class="mb-0 text-left py-2">O primeiro marketplace financeiro do Brasil.</h3> */}
                        </div>

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://investsmartxp.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/InvestSmartXP"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/investsmartxp"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/investsmart.xp/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  {
                    templatebe === 3 ? (
                      <div class="info-company-window">
                        <div class="company-profile-logo d-block">
                          <img src={templateimoveis}/>
                        </div>
                       
                        <div class="company-slogan">
                           <h3 class="mb-0 mt-20 text-left py-2"></h3> 
                        </div> 

                        <div class="social-media">
                          <div class="row contents">
                            <span id="glob" class="glob-icon"><a id="glob-link" target="_blank" href="https://www.besmartimoveis.com.br/"><i><FontAwesomeIcon icon={faGlobe} /></i></a></span>
                            <span id="face" class="facebook-icon"><a id="face-link" target="_blank" href="https://www.facebook.com/besmartimoveis"><i><FontAwesomeIcon icon={faFacebookF} /></i></a></span>
                            <span id="linke" class="linkedin-icon"><a id="linke-link" target="_blank" href="https://www.linkedin.com/company/besmartimoveis"><i><FontAwesomeIcon icon={faLinkedinIn} /></i></a></span>
                            <span id="insta" class="instagram-icon"><a id="insta-link" target="_blank" href="https://www.instagram.com/be.smart_imoveis/"><i><FontAwesomeIcon icon={faInstagram} /></i></a></span>
                            <span id="youtube" class="youtube-icon"><a id="youtube-link" target="_blank" href=" https://www.youtube.com/channel/UCx3OKKUpY3jwu_KQT15akAg"><i><FontAwesomeIcon icon={faYoutube}/> </i></a></span>

                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="footer-newsletter-opt">
            <div class="create-newsletter">
              {/** 
            <Link to={{
                pathname:"/passo-tres",
                state:{ profile, nome, sobrenome, email, cargo, cellcomercial, cellphone, endereco1, url, template}}}></Link>
            */}
            <span class="create-btn bg-success" onClick={verificarDados}>Finalizar Assinatura</span>
            </div>
            <div class="clear-all" id="clear-btn">
              <i><FontAwesomeIcon icon={faRedoAlt} /></i>
              <span class="clean-text" onClick={clear}>Apagar todos os campos de entrada</span>
            </div>
          </div>
        </div>
    </div>

    {/* FAQ */}

    

    <div class="faqs-layout" id="faqs-page">
      <div class="faqs-content">
          <div class="faqs-content-header">
              <h2>Perguntas Frequentes</h2>
          </div>
          <div class="faqs-content-body">               
              <h3>Como uso essa ferramenta?</h3>
              <ul>
                  <li class="stepitems"> 
                      <span>Na primeira aba do editor, selecione o modelo que quer aplicar à sua assinatura.</span>
                      <img src={img1}/>
                  </li>
                  <li class="stepitems">
                      <span>Clique na segunda aba para inserir os detalhes de assinatura de e-mail, como nome, endereço de e-mail, número de telefone etc.</span>
                      <img src={img2}/>
                  </li>
                  <li class="stepitems">
                      <span>Clique na terceira aba para definir sua foto.</span>
                      <img src={img3}/>
                  </li>
                  <li class="stepitems">
                      <span>Depois que acabar de editar sua assinatura, clique no botão “Finalizar assinatura” para confirmar seus dados e desbloquear seu uso.</span>
                  </li>
              </ul>

              <hr class="faqs-divisor"/>

              <h3>Finalizado a criação da sua assinatura</h3>
              <p>Depois do preenchimento correto dos seus dados para criação da assinatura, a imagem a seguir é um exemplo de como ficará sua assinatura.</p>
              <img src={img4}/>
              <p>Lembrando que você poderá copiar o HMTL da sua assinatura, como também adicionar ao Outlook. Na próxima seção, explicaremos como efetuar esse passo.</p>

            

              <hr class="faqs-divisor"/>

              <h3>Como adiciono minha nova assinatura de e-mail ao Outlook?</h3>
              <ul>
                  <li class="stepitems">
                      <span>Entre na sua conta do Outlook e selecione Início e Novo e-mail.</span>
                  </li>
                  <li class="stepitems">
                      <span>Clique na aba de mensagens.</span>
                  </li>
                  <li class="stepitems">
                      <span>Vá para a seção Incluir grupo  Assinatura  Assinaturas.</span>
                  </li>
                  <li class="stepitems">
                      <span>Na seção 'Escolher assinatura padrão', veja se está na conta certa que deseja associar com essa assinatura no menu suspenso de contas de e-mail.</span>
                  </li>
                  <li class="stepitems">
                      <span>Na seção 'Selecionar assinatura para editar', clique no botão 'Novo' e nomeie a sua assinatura.</span>
                  </li>
                  <li class="stepitems">
                      <span>Na seção 'Editar assinatura', cole sua assinatura da InvestSmart e selecione OK.</span>
                  </li>
              </ul>

              <hr class="faqs-divisor"/>

              <h3>Estou no celular. Como posso criar minha assinatura de e-mail?</h3>
              <p>O gerador de modelo de assinatura de e-mail da InvestSmart atualmente só funciona no desktop. Isso nos ajuda a garantir que você terá a melhor experiência possível. Se estiver em um dispositivo móvel, siga as instruções para enviar a ferramenta por e-mail para você mesmo para usá-la no desktop mais tarde.</p>
              <img src={img5}/>

              <hr class="faqs-divisor"/>

              <h3>Por que a minha assinatura não está como eu queria?</h3>
              <p>Se sua assinatura não está como você queria, não se preocupe. É fácil corrigir. Veja algumas ideias:</p>
              <ul>
                  <li class="stepitems">
                      <span>Tente usar um navegador diferente para criar sua assinatura. Alguns navegadores não copiam o formato junto com o texto e isso muda a cada atualização dos navegadores.</span>
                  </li>
              </ul>
          </div>
      </div>
      <span class="close-btn" id="close-faqspage" onClick={closingFaqs}>
          <FontAwesomeIcon icon={faTimes} />
      </span>
  </div>
</>
  );
}

export default StepOneBe;
