import React, {useState} from "react";
import './styles.css';

import InputMask from 'react-input-mask';




const Info = ({setNome, setSobrenome, setEmail, setCargo, setCellcomercial, setCellphone, setEndereco1, setURL, template, cargo}) => {

    
    function mudarClasse (label) {
        let removerClasse = document.getElementById(label);
        removerClasse.classList.remove('error-input');
    }


    return (
        <div  class="content-bar " id="info">
            <div class="title-bar pt-3 pl-2">
                <h2>Insira as informações para a assinatura</h2>
            </div>
            <form>
                <div id="firstName" className="ajuste-divs-info mb-4">
                    <div className="div-infos">
                        <input
                            id="Nome" 
                            name="nome" 
                            type="text"
                            placeholder="Nome*"
                            class="input-infos"
                            onChange={(event) => {
                                setNome(event.target.value)
                                mudarClasse("Nome")
                            }}
                            required>
                        </input>
                        <p hidden id="error-sub" className="error-sub">Preencha esse campo</p>
                    </div>
                </div>

                <div id="lastName" className="ajuste-divs-info my-5">
                    <div className="div-infos">
                        <input
                            id="Sobrenome"
                            name="sobrenome"
                            type="text"
                            placeholder="Sobrenome*"
                            class="input-infos"
                            onChange={(event) => {
                                setSobrenome(event.target.value)
                                mudarClasse("Sobrenome")
                            }}
                            required>
                        </input>
                        <p hidden id="error-sub" className="error-sub">Preencha esse campo</p>
                    </div>
                </div>

                <div id="lastName" className="ajuste-divs-info my-5">
                    <div className="div-infos">
                        <input
                            id="Email"
                            name="email"
                            type="text"
                           
                            placeholder="Email*"
                            class="input-infos"
                            onChange={(event) => {
                                setEmail(event.target.value)
                                mudarClasse("Email")
                            }}
                            required>
                        </input>
                        <p hidden id="error-sub" className="error-sub">Preencha esse campo</p>
                    </div>
                </div>

                <div id="jobTitle   " className="ajuste-divs-info my-5">
                    <div className="div-infos">
                        <input
                            id="Cargo"
                            name=""
                            type="text"
                            disabled={template === 1  ? true : false}
                            placeholder="Setor*"
                            class="input-infos"
                            value={cargo}
                            onChange={(event) => {
                                setCargo(event.target.value)
                                mudarClasse("Cargo")
                            }}
                            required>
                        </input>
                        <p hidden id="error-sub" className="error-sub">Preencha esse campo</p>
                    </div>
                </div>
                
                <div id="mobilePhone" className="ajuste-divs-info my-5">
                    <div className="div-infos">
                        <InputMask 
                        id="Celular"
                        mask="(99) 99999-9999"
                        type="text"
                        placeholder="Telefone Celular*"
                        class="input-infos"
                        onChange={(event) => {
                            setCellphone(event.target.value)
                            mudarClasse("Celular")
                        }}
                        required
                        />
                        <p hidden id="errorname" className="error-sub">Preencha esse campo</p>
                    </div>
                </div>

                <div id="officePhone" className="ajuste-divs-info my-5">
                    <div className="div-infos">
                        <InputMask
                        id="telComercial"
                        mask="(99) 9999-9999"
                        type="text" 
                        placeholder="Telefone Comercial" 
                        class="input-infos" 
                        onChange={(event) => setCellcomercial(event.target.value)} 
                        />
                    </div>
                </div>

                <div id="emailAdress" className="ajuste-divs-info my-5">
                    <div className="div-infos">
                        <input
                            id="endereco"
                            name=""
                            type="text"
                            placeholder="Endereço"
                            class="input-infos"
                            onChange={(event) => setEndereco1(event.target.value)}
                            >
                        </input>
                    </div>
                </div>

                <div id="emailAdress" className="ajuste-divs-info my-5">
                    <div className="div-infos">
                   
                        <input
                            id="url"
                            name=""
                            type="text"
                            placeholder="URL da sua Landing Page/Calendário"
                            class="input-infos"
                            onChange={(event) => setURL(event.target.value)}
                            >
                        </input>
                    </div>
                </div>

                
                <div class="footer-bar text-center">
                    <span class="copyright-text">Copyright ©  InvestSmart, Inc. Desenvolvimento & Processos.</span>
                </div>
            </form>
        </div>
    );
}

export default Info;